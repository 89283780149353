import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"
import styled from "styled-components"
import useSticky from "../hooks/useSticky"

const Footer = styled.footer`
  padding: 4em 0 8em 0;
  clear: both;
  font-size: 14px;
  color: #707070;
  background-color: #f7f7f7;

  @media (max-width: 768px) {
    margin-top: 50px;
    padding: 2em 0;
  }
`

const FooterList = styled.ul`
  cursor: default;
  list-style: none;
  padding-left: 0;
  text-align: center;
  margin: 0;
`

const FooterItem = styled.li`
  display: inline-block;
  padding: 0 0 0 1em;
  margin: 0 0 0 1em;
  border-left: solid 1px #e0e0e0;

  :first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
  }
`

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
  font-weight: 600;

  :visited {
    color: inherit;
  }

  :hover {
    text-decoration: underline;
  }
`

const StyledSocialLink = styled.svg`
  fill: grey;
  transition: fill 0.4s ease;

  :hover {
    fill: black;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { isSticky, element } = useSticky()

  useEffect(() => {
    document.addEventListener("contextmenu", e => {
      e.preventDefault()
    })
  }, [])

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        sticky={isSticky}
        element={element}
      />
      <main>{children}</main>
      <Footer>
        <FooterList>
          <FooterItem>
            <a
              href="https://www.facebook.com/francozizolascrittore/"
              target="_blank"
              rel="noreferrer"
            >
              <StyledSocialLink version="1.1" viewBox="0 0 45 45" width="45px">
                <title>Link a Facebook</title>
                <g transform="matrix(0.12 0 0 0.12 0 0)">
                  <path
                    d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
		c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
		V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
		C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
		c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"
                  />
                </g>
              </StyledSocialLink>
            </a>
          </FooterItem>
        </FooterList>
        <FooterList>
          <FooterItem>© {new Date().getFullYear()}</FooterItem>
          <FooterItem>All rights reserved</FooterItem>
          <FooterItem>
            Built with
            {` `}
            <StyledLink
              href="https://www.gatsbyjs.com"
              target="_blank"
              rel="noreferrer"
            >
              Gatsby
            </StyledLink>
          </FooterItem>
        </FooterList>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
