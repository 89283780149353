import React from "react"
import SubmenuItem from "./submenuitem"

const Submenu = ({ pages, closeMenu }) => {
  return (
    <>
      {pages.map((page, idx) => (
        <SubmenuItem
          key={idx}
          slug={page.slug}
          name={page.name}
          closeMenu={closeMenu}
        />
      ))}
    </>
  )
}

export default Submenu
