import React from "react"
import styled from "styled-components"

const Container = styled.div`
  color: #777;
  width: 45px;
  height: 45px;
  transition: all 0.5s linear;
  align-self: center;
  position: relative;
  float: left;
  margin-left: 45px;
  z-index: 1;
  line-height: 45px;
  font-size: 16px;
`

const Hamburger = props => {
  return (
    <>
      <svg
        height="45px"
        version="1.1"
        viewBox="0 0 45 45"
        width="45px"
        position="fixed"
        style={{ position: "fixed", zIndex: 1, fill: "#777", margin: "5px" }}
      >
        <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z" />
      </svg>
      <Container {...props.open}>Menu</Container>
    </>
  )
}

export default Hamburger
