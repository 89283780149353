import React from "react"
import NavItem from "./navitem"
import useLocation from "../hooks/useLocation"
import { useStaticQuery, graphql } from "gatsby"

const NavbarLinks = ({ closeMenu }) => {
  const { location } = useLocation()
  const currentPath =
    location.pathname.length === 1
      ? "/"
      : location.pathname.substring(
          0,
          location.pathname.substring(1).indexOf("/") + 2
        )

  const data = useStaticQuery(
    graphql`
      query QueryNavMenu {
        allMdx(
          filter: { frontmatter: { nav_order: { ne: null } } }
          sort: { frontmatter: {nav_order: ASC } }
        ) {
          edges {
            node {
              id
              frontmatter {
                slug
                title
              }
            }
          }
        }
      }
    `
  )

  const pages = data.allMdx.edges

  return (
    <>
      {pages.map((page, idx) => {
        return <NavItem
          key={idx}
          slug={page.node.frontmatter.slug}
          name={page.node.frontmatter.title}
          closeMenu={closeMenu}
          selected={currentPath === page.node.frontmatter.slug}
        />
      })}
    </>
  )
}

export default NavbarLinks
