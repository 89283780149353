exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-bio-template-js-content-file-path-src-mdx-pages-index-mdx": () => import("./../../../src/templates/bioTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/index.mdx" /* webpackChunkName: "component---src-templates-bio-template-js-content-file-path-src-mdx-pages-index-mdx" */),
  "component---src-templates-contacts-template-js-content-file-path-src-mdx-pages-contatti-mdx": () => import("./../../../src/templates/contactsTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/contatti.mdx" /* webpackChunkName: "component---src-templates-contacts-template-js-content-file-path-src-mdx-pages-contatti-mdx" */),
  "component---src-templates-review-template-js-content-file-path-src-mdx-pages-recensione-otello-rosa-mdx": () => import("./../../../src/templates/reviewTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/recensione-otello-rosa.mdx" /* webpackChunkName: "component---src-templates-review-template-js-content-file-path-src-mdx-pages-recensione-otello-rosa-mdx" */),
  "component---src-templates-reviews-template-js-content-file-path-src-mdx-pages-recensioni-mdx": () => import("./../../../src/templates/reviewsTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/recensioni.mdx" /* webpackChunkName: "component---src-templates-reviews-template-js-content-file-path-src-mdx-pages-recensioni-mdx" */),
  "component---src-templates-work-template-js-content-file-path-src-mdx-pages-il-convittore-mdx": () => import("./../../../src/templates/workTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/il-convittore.mdx" /* webpackChunkName: "component---src-templates-work-template-js-content-file-path-src-mdx-pages-il-convittore-mdx" */),
  "component---src-templates-work-template-js-content-file-path-src-mdx-pages-il-convittore-una-storia-d-amore-mdx": () => import("./../../../src/templates/workTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/il-convittore-una-storia-d-amore.mdx" /* webpackChunkName: "component---src-templates-work-template-js-content-file-path-src-mdx-pages-il-convittore-una-storia-d-amore-mdx" */),
  "component---src-templates-work-template-js-content-file-path-src-mdx-pages-il-sogno-di-orfeo-mdx": () => import("./../../../src/templates/workTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/il-sogno-di-orfeo.mdx" /* webpackChunkName: "component---src-templates-work-template-js-content-file-path-src-mdx-pages-il-sogno-di-orfeo-mdx" */),
  "component---src-templates-work-template-js-content-file-path-src-mdx-pages-la-chiave-nel-pozzo-mdx": () => import("./../../../src/templates/workTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/la-chiave-nel-pozzo.mdx" /* webpackChunkName: "component---src-templates-work-template-js-content-file-path-src-mdx-pages-la-chiave-nel-pozzo-mdx" */),
  "component---src-templates-work-template-js-content-file-path-src-mdx-pages-la-mano-di-dio-mdx": () => import("./../../../src/templates/workTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/la-mano-di-dio.mdx" /* webpackChunkName: "component---src-templates-work-template-js-content-file-path-src-mdx-pages-la-mano-di-dio-mdx" */),
  "component---src-templates-work-template-js-content-file-path-src-mdx-pages-la-valle-serena-mdx": () => import("./../../../src/templates/workTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/la-valle-serena.mdx" /* webpackChunkName: "component---src-templates-work-template-js-content-file-path-src-mdx-pages-la-valle-serena-mdx" */),
  "component---src-templates-work-template-js-content-file-path-src-mdx-pages-le-favole-di-isabella-mdx": () => import("./../../../src/templates/workTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/le-favole-di-isabella.mdx" /* webpackChunkName: "component---src-templates-work-template-js-content-file-path-src-mdx-pages-le-favole-di-isabella-mdx" */),
  "component---src-templates-work-template-js-content-file-path-src-mdx-pages-leopardi-mdx": () => import("./../../../src/templates/workTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/leopardi.mdx" /* webpackChunkName: "component---src-templates-work-template-js-content-file-path-src-mdx-pages-leopardi-mdx" */),
  "component---src-templates-work-template-js-content-file-path-src-mdx-pages-roghi-mdx": () => import("./../../../src/templates/workTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/roghi.mdx" /* webpackChunkName: "component---src-templates-work-template-js-content-file-path-src-mdx-pages-roghi-mdx" */),
  "component---src-templates-work-template-js-content-file-path-src-mdx-pages-ruber-palus-palo-rosso-mdx": () => import("./../../../src/templates/workTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/ruber-palus-palo-rosso.mdx" /* webpackChunkName: "component---src-templates-work-template-js-content-file-path-src-mdx-pages-ruber-palus-palo-rosso-mdx" */),
  "component---src-templates-works-template-js-content-file-path-src-mdx-pages-opere-mdx": () => import("./../../../src/templates/worksTemplate.js?__contentFilePath=/opt/build/repo/src/mdx-pages/opere.mdx" /* webpackChunkName: "component---src-templates-works-template-js-content-file-path-src-mdx-pages-opere-mdx" */)
}

