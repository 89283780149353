import NavbarLinks from "./navbarlinks"
import styled from "styled-components"
import React from "react"
import voidImage from "../images/void-gradient.png"

const Navigation = styled.nav`
  display: block;
  background-color: #333;
  justify-content: space-between;
  margin: 0;
  align-self: center;
  text-align: center;
  z-index: 999;
  font-size: 16px;
  background-image: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.3)
    ),
    url(${voidImage});

  :after {
    content: "";
    display: block;
    width: 100%;
    height: 0.75em;
    background-color: #37c0fb;
  }

  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    left: 0;

    :after {
      content: "";
      height: 0.3em;
    }
  }
`

const Navbox = styled.ul`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    height: calc(100% - 46px);
    overflow-y: scroll;
    justify-content: flex-start;
    padding-top: 10vh;
    background-color: #1f1f1f;
    transition: all 0.5s ease;
    left: ${props => (props.open ? "-100%" : "0")};
  }
`

const Navbar = ({ navbarOpen, closeMenu, sticky }) => {
  return (
    <Navigation className={sticky ? "navbar-sticky" : ""}>
      {navbarOpen ? (
        <Navbox>
          <NavbarLinks closeMenu={closeMenu} />
        </Navbox>
      ) : (
        <Navbox open>
          <NavbarLinks closeMenu={closeMenu} />
        </Navbox>
      )}
    </Navigation>
  )
}

export default Navbar
