import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Item = styled.li`
  width: 200px;
  position: relative;
  font-size: 0.8em;

  @media (max-width: 768px) {
    width: 100%;
  }
`
const LinkMainText = styled.span``

const LinkSubText = styled.span`
  font-size: 0.75em;
`

const NavLink = styled(Link)`
  text-decoration: none;
  color: #c0c0c0;
  display: block;
  padding: 0.7em 1em;

  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;

  :hover {
    color: white;
  }

  @media (max-width: 768px) {
    color: #fff;
    padding: 10px 0;
    font-size: 1.3em;
    z-index: 6;
  }
`
const SubmenuItem = ({ name, slug, closeMenu }) => {
  const [mainName, subName] = name.split('\n');

  return (
    <Item>
      <NavLink
        to={slug}
        onClick={() => {
          closeMenu()
        }}
      >
        <LinkMainText>{mainName}</LinkMainText>
        {subName?<LinkSubText><br></br>{subName}</LinkSubText>:''}
      </NavLink>
    </Item>
  )
}

export default SubmenuItem
