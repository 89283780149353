import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import Submenu from "./submenu"

const Item = styled.li`
  list-style-type: none;
  width: 120px;

  :hover ul {
    display: block;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`
const SubmenuContainer = styled.ul`
  display: none;
  margin: 0 0 0 -40px;
  padding: 0;
  list-style-type: none;
  background-color: #333;
  position: absolute;
  z-index: 1;

  @media (max-width: 768px) {
    display: block;
    position: relative;
    color: #fff;
    margin-left: 0;
    background-color: inherit;
  }
`

const NavLink = styled(Link)`
  text-decoration: none;
  text-align: center;
  color: #c0c0c0;
  display: block;
  padding: 1.5em 0.5em 1.35em 0.5em;
  width: 120px;

  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;

  :hover {
    color: white;
  }

  @media (max-width: 768px) {
    color: #fff;
    padding: 15px 0;
    font-size: 1.5rem;
    z-index: 6;
    width: 100%;
  }
`
const NavItem = ({ name, slug, selected, closeMenu }) => {
  const isSelected = selected === true
  const className = isSelected ? "selected" : ""

  const data = useStaticQuery(
    graphql`
      query QueryNavSubmenu {
        allMdx(
          filter: { frontmatter: { parent: { ne: null } } }
          sort: { frontmatter: {year: ASC } }
        ) {
          edges {
            node {
              id
              frontmatter {
                slug
                title
                parent
              }
            }
          }
        }
      }
    `
  )

  const pages = data.allMdx.edges
  const filteredPages = pages.filter(
    page => page.node.frontmatter.parent === name
  )
  var submenu = []
  filteredPages.forEach(page => {
    let submenuItem = {
      slug: page.node.frontmatter.slug,
      name: page.node.frontmatter.title,
    }
    submenu.push(submenuItem)
  })

  return (
    <Item>
      <NavLink
        to={slug}
        className={className}
        onClick={() => {
          closeMenu()
        }}
      >
        {name}
      </NavLink>
      {submenu ? (
        <SubmenuContainer>
          <Submenu pages={submenu} closeMenu={closeMenu} />
        </SubmenuContainer>
      ) : (
        <></>
      )}
    </Item>
  )
}

export default NavItem
