import { useEffect, useState, useRef } from "react"

function useSticky() {
  const [isSticky, setSticky] = useState(false)
  const element = useRef(null)

  
  useEffect(() => {
    const handleScroll = () => {
      window.scrollY > element.current?.getBoundingClientRect().bottom
        ? setSticky(true)
        : setSticky(false)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", () => handleScroll)
    }
  }, [])

  return { isSticky, element }
}

export default useSticky
