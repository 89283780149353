import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import React, { useState } from "react"
import Hamburger from "./hamburger"
import Navbar from "./navbar"

const MenuContainer = styled.div`
  @media (max-width: 768px) {
    position: fixed;
    width: 100%;
    z-index: 1;
  }
`

const Titlebar = styled.div`
  text-align: center;
  padding: 3em 0 0 0;
  background-color: #fff;

  @media (max-width: 768px) {
    color: white;
    background: linear-gradient(
      180deg,
      rgba(51, 51, 51, 1) 0%,
      rgba(34, 34, 34, 1) 100%
    );
    position: sticky;
    height: 45px;
    padding: 0;
  }
`

const TitleHeading = styled.h1`
  padding: 0 0 1.3em 0;
  margin: 0;

  @media (max-width: 768px) {
    padding: 0;
    line-height: 0.9em;
  }
`

const TitleLink = styled(Link)`
  letter-spacing: -0.025em;
  border: 0;
  color: inherit;
  text-decoration: none;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;

  :hover {
    color: #37c0fb;
  }

  @media (max-width: 768px) {
    font-size: 16px;

    :hover {
      color: inherit;
    }
  }
`

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;

  @media (max-width: 768px) {
    float: left;
    display: block;
  }
`

const Header = ({ siteTitle, sticky, element }) => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const handleCloseMenu = () => setNavbarOpen(false)

  return (
    <MenuContainer ref={element}>
      <div>
        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <Hamburger navbarOpen />
        </Toggle>
        <Titlebar>
          <TitleHeading>
            <TitleLink to="/">{siteTitle}</TitleLink>
          </TitleHeading>
        </Titlebar>
      </div>
      <Navbar
        navbarOpen={navbarOpen}
        closeMenu={handleCloseMenu}
        sticky={sticky}
      />
    </MenuContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
